import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql, Link } from "gatsby"

export default function ProductPost({ data }) {
  //console.log('product inner pageeeeeee')
  const post = data.post;
 //console.log('posttt:: ',post)
  return (
    <Layout>
      <section className="pageHeader" style={{ background: post.featuredImage != null ? `url(${post.featuredImage.node.mediaItemUrl})` : '#d67373' }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <nav>
                <ol className="breadcrumb">
                <li className='breadcrumb-item'><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active">{post.title}</li>
                </ol>
              </nav>
              <h1 className="pagetitle__heading entry-header-inner">{post.title}</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="postWrapper">
        <div className="postContentWrapper">
          <div className="postContent">
            <div 
              key={post.id} 
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    post: wpPost(slug: { eq: $slug }) {
     id
    uri
    title
    categories {
      nodes {
        id
        link
      }
    }
    content
    featuredImage {
      node {
        mediaItemUrl
      }
    }
  }



    # allWpPost(filter: { slug: { eq: $slug } }) {
    #   nodes {
    #     title
    #     content
    #   }
    # }
  }
`